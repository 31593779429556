import topic from '../topic';
import { getNoCatch, postNoCatch } from '@api/request';

export const reimAPI = {
  getReims: topic.reimTopic + '/get_reims',
  getReimById: topic.reimTopic + '/get_reim_by_id',
  addReim: topic.reimTopic + '/add_reim',
  editReim: topic.reimTopic + '/edit_reim',
  deleteReimByIds: topic.reimTopic + '/delete_reim_by_ids',
  getReimListByRichMax: params => getNoCatch(`${topic.reimTopic}/getReimListByRichMax`, params),
  addReimByRichMax: params => postNoCatch(`${topic.reimTopic}/addReimByRichMax`, params)
};
