<template>
  <div>
    <el-col :span="24">
      <el-table :data="reimForm.reim_fees_list" border @selection-change="handleSelectionChange" :header-cell-class-name="'vg_deep_red'">
        <el-table-column type="selection" width="48" align="center"></el-table-column>
        <el-table-column label="序号" width="60" align="center">
          <template slot="header">
            <span style="color: #8f9398 !important">序号</span>
          </template>
          <template v-slot="scope">
            <span>
              {{ scope.$index + 1 }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="费用科目">
          <template v-slot="scope">
            <el-form-item label-width="0" :prop="'reim_fees_list.' + scope.$index + '.reim_fees_type'" :rules="{ required: true }">
              <el-select v-model="scope.row.reim_fees_type" placeholder="请选择费用科目" filterable>
                <el-option v-for="item in optnList" :key="item.id" :value="item.param1" :label="item.param1"></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="费用金额">
          <template v-slot="scope">
            <el-form-item label-width="0" :prop="'reim_fees_list.' + scope.$index + '.reim_fees_rmb'" :rules="{ required: true }">
              <el-input
                v-model="scope.row.reim_fees_rmb"
                @change="reimFeesRmbChange"
                @input="val => (scope.row.reim_fees_rmb = keep2Decimal(val))"
                maxlength="11"
                show-word-limit
                placeholder="请填写费用金额"
                size="mini"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="费用对象">
          <template v-slot="scope">
            <el-form-item label-width="0" :prop="'reim_fees_list.' + scope.$index + '.reim_fees_obje'" :rules="{ required: true }">
              <el-select v-model="scope.row.reim_fees_obje" placeholder="请选择" filterable @change="val => changeObj(val, scope.$index)">
                <el-option v-for="item in feesObjeList" :key="item.id" :label="item.param3" :value="item.id"></el-option>
              </el-select>
              <!--<el-input-->
              <!--  v-model="scope.row.reim_fees_obje"-->
              <!--  maxlength="20"-->
              <!--  show-word-limit-->
              <!--  placeholder="请填写费用对象"-->
              <!--  size="mini"-->
              <!--&gt;</el-input>-->
            </el-form-item>
          </template>
        </el-table-column>
        <!--<el-table-column label="申请部门">-->
        <!--  <template v-slot="scope">-->
        <!--    <el-form-item label-width="0" :prop="'reim_fees_list.' + scope.$index + '.reim_dept_id'" :rules="{ required: true }">-->
        <!--      <el-select v-model="scope.row.reim_dept_id" placeholder="请选择申请部门" filterable>-->
        <!--        <el-option v-for="item in deptList" :key="item.dept_id" :value="item.dept_id" :label="item.dept_name"></el-option>-->
        <!--      </el-select>-->
        <!--    </el-form-item>-->
        <!--  </template>-->
        <!--</el-table-column>-->
        <el-table-column label="申请部门">
          <template v-slot="scope">
            <!--            <el-form-item label-width="0" :prop="'reim_fees_list.' + scope.$index + '.reim_dept_name'" :rules="{ required: true }">-->
            <!--              <el-input v-model="scope.row.reim_dept_name" placeholder="请填写申请部门" size="mini" disabled></el-input>-->
            <!--            </el-form-item>-->
            <el-form-item label-width="0" :prop="'reim_fees_list.' + scope.$index + '.reim_fees_obje'" :rules="{ required: true }">
              <el-select v-model="scope.row.reim_dept_name" placeholder="请选择申请部门" filterable>
                <el-option v-for="item in scope.row.feesDeptList" :key="item.id" :label="item.param4" :value="item.param4"></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="用途">
          <template v-slot="scope">
            <el-form-item label-width="0" :prop="'reim_fees_list.' + scope.$index + '.reim_fees_purp'" :rules="{ required: true }">
              <el-input v-model="scope.row.reim_fees_purp" maxlength="255" show-word-limit placeholder="请填写费用对象" size="mini"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
      </el-table>
    </el-col>
  </div>
</template>

<script>
import { get, getNoCatch } from '@api/request';
import { deptAPI } from '@api/modules/department';
import { optnAPI } from '@api/modules/optn';
import { custAPI } from '@api/modules/cust';
import { keep2Decimal } from '@assets/js/regExUtil';
import { getDataCenterByPermId } from '@api/public';
import { cloneDeep } from 'lodash';

export default {
  name: 'ReimFees',
  props: ['reimForm'],
  computed: {
    // tableData() {
    // 	return this.reimFeesTableData
    // }
  },
  data() {
    return {
      deptList: [],
      optnList: [],
      custList: [],
      deptTeamsGroupOpt: [],
      optList: [],
      feesObjeList: []
    };
  },
  async mounted() {
    this.optList = await getDataCenterByPermId({ id: 10047, type: 'no' });
    //数据中心去重
    const seen = new Set();
    this.feesObjeList = this.optList.filter(item => {
      if (!seen.has(item['param3'])) {
        seen.add(item['param3']);
        return true;
      }
      return false;
    });
    this.setPartDeptList(); //加载子表部门下拉
    this.getStffUser();
    this.getCostCategory();
    // this.getCust();
    this.getDeptTeams();
  },
  methods: {
    keep2Decimal,
    getCust() {
      getNoCatch(custAPI.getAllCustsV1).then(({ data }) => {
        this.custList = data.list;
      });
    },
    setPartDeptList() {
      //set每个子表对应的申请部门
      this.reimForm.reim_fees_list.forEach(item => {
        this.$set(
          item,
          'feesDeptList',
          this.optList.filter(item2 => item2.param3 === item.reim_fees_obje)
        );
      });
    },
    //子表费用对象联动
    changeObj(val, index) {
      let find = this.feesObjeList.find(x => x.id === val);
      //联动子表费用对象和部门并且过滤出对应的申请部门
      this.reimForm.reim_fees_list[index].reim_fees_obje = cloneDeep(find.param3);
      this.$set(this.reimForm.reim_fees_list[index], 'reim_dept_name', cloneDeep(find.param4));
      this.$set(
        this.reimForm.reim_fees_list[index],
        'feesDeptList',
        this.optList.filter(item => item.param3 === find.param3)
      );
    },
    getDeptTeams() {
      get(deptAPI.getAllDeptTeams).then(res => {
        if (res.data.code === 0) {
          this.deptTeamsGroupOpt = res.data.data;
        }
      });
    },
    // 指定外销员list
    getStffUser() {
      get(deptAPI.getAllDeptsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.deptList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    getCostCategory() {
      get(optnAPI.getOptnByPermId, { perm_id: 10023 })
        .then(res => {
          if (res.data.code === 0) {
            this.optnList = res.data.data.form.optn_cntt_list;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    showIndex(scope) {
      return scope.$index + 1;
    },
    reimFeesRmbChange() {
      setTimeout(() => {
        this.$emit('reimFeesRmbChange');
      }, 200);
    },
    handleSelectionChange(val) {
      //console.log(val)
      this.$emit('handleSelectionChange', val);
    }
  }
};
</script>

<style scoped></style>
