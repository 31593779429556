<template>
  <div class="vg_wrapper" v-loading="loadingFlag">
    <div class="vd_edhea vg_button_group">
      <editHeader :btn="btn" :isShow="isShow" :strForm="reimForm" @closeEdit="closeEdit" @openEdit="openEdit" @submit="submit('reimForm')" />
      <div class="vd_export" v-if="isShow">
        <el-button type="primary" size="small" @click="buttonRefresh()">刷新</el-button>
      </div>
      <div class="vd_export2 vg_mr_8">
        <span style="margin-right: 2px">费用报销单号:</span>
        <el-input size="mini" style="width: 150px" disabled v-model="reimForm.reim_no" show-word-limit> </el-input>
      </div>
    </div>
    <el-form ref="reimForm" :model="reimForm" :rules="rules" label-width="120px" size="mini" :disabled="isShow">
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="用友对接状态" prop="yongYouStatus" v-if="yongYouStatus === '已抓取'">
            <el-input v-model="yongYouStatus" type="input" disabled> </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="申请人" prop="cust_stff_aid">
            <el-select v-model="reimForm.cust_stff_aid" size="small" placeholder="请选择申请人" filterable @change="stffChange">
              <el-option v-for="item in stffList" :key="item.stff_id" :value="item.stff_id" :label="item.stff_name"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="公司名称" prop="cptt_aid">
            <el-select v-model="reimForm.cptt_id" size="small" placeholder="请选择公司名称" filterable>
              <el-option v-for="item in cpttList" :key="item.cptt_id" :value="item.cptt_id" :label="item.cptt_name"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="申请日期" prop="reim_appdate">
            <el-date-picker v-model="reimForm.reim_appdate" type="date" placeholder="选择申请日期"> </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="报销人" prop="cust_stff_bid">
            <el-select v-model="reimForm.cust_stff_bid" size="small" placeholder="请选择报销人" filterable>
              <el-option v-for="item in stffList" :key="item.stff_id" :value="item.stff_id" :label="item.stff_name"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="总金额" prop="reim_total">
            <el-input v-model="reimForm.reim_total" disabled maxlength="30" show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="报销日期" prop="reim_reidate">
            <el-date-picker v-model="reimForm.reim_reidate" type="date" placeholder="选择报销日期"> </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="vd_dash"></div>
      <el-row class="vg_mb_5">
        <el-col>
          <div class="vg_mb_16">
            <el-button type="primary" plain size="small" @click="addNewTableCell">新增</el-button>
            <el-button type="danger" plain size="small" @click="delPurce">删除</el-button>
          </div>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="24">
          <ReimFees :reimForm="reimForm" ref="reimFeesRef" @reimFeesRmbChange="reimFeesRmbChange" @handleSelectionChange="handleSelectionChange" />
        </el-col>
      </el-row>

      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="false" :stffForm="stffForm" ref="userData" v-if="reimForm.stff_name"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { reimAPI } from '@api/modules/reim';
import { cpttAPI } from '@api/modules/comptitle';
import editHeader from '@/views/component/editHeader';
import inputUser from '@/views/component/inputUser';
import { BigNumber } from 'bignumber.js';
import { stffAPI } from '@api/modules/staff';
import ReimFees from '@/views/OfficeManagement/ReimManage/TabChild/Component/ReimFees';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'ReimEditMain',
  components: {
    editHeader,
    inputUser,
    ReimFees
  },
  data() {
    return {
      reimFeesListCopy: [],
      rules: {
        cust_stff_aid: [{ required: true, trigger: 'blur', message: ' ' }],
        cust_stff_bid: [{ required: true, trigger: 'blur', message: ' ' }]
      },
      reimForm: {},
      isShow: true,
      btn: {},
      stffForm: {},
      loadingFlag: false,
      stffList: [],
      cpttList: [],
      yongYouStatus: '未抓取'
    };
  },
  created() {
    this.initData();
  },
  watch: {},
  methods: {
    stffChange() {
      for (let i = 0; i < this.stffList.length; i++) {
        if (this.stffList[i].stff_id === this.reimForm.cust_stff_aid) {
          this.reimForm.cptt_id = this.stffList[i].cptt_id;
        }
      }
    },
    //获取公司抬头
    getCptt() {
      get(cpttAPI.getAllCpttsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.cpttList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 指定外销员list
    getStffUser() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.stffList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 选择删除值
    handleSelectionChange(val) {
      this.selectList = JSON.parse(JSON.stringify(val));
    },
    calcEcho() {
      let totalA = new BigNumber(0);
      for (let i = 0; i < this.reimForm.reim_fees_list.length; i++) {
        totalA = totalA.plus(this.reimForm.reim_fees_list[i].reim_fees_rmb ? this.reimForm.reim_fees_list[i].reim_fees_rmb : 0);
      }
      this.reimForm.reim_total = totalA.toFixed(2);
    },
    reimFeesRmbChange() {
      this.calcEcho();
    },
    // 删除导入发票明细
    delPurce() {
      let temp = [];
      this.selectList.forEach(item => {
        temp.push(item.key);
      });
      temp = temp.sort((a, b) => {
        return b - a;
      });
      for (let i of temp) {
        this.reimForm.reim_fees_list.splice(i, 1);
      }
      for (let i = 0; i < this.reimForm.reim_fees_list.length; i++) {
        this.reimForm.reim_fees_list[i].key = i;
      }
      this.calcEcho();
    },
    // 新增一条子表空数据
    addNewTableCell() {
      let item = {
        reim_fees_type: '',
        reim_fees_rmb: '',
        reim_fees_obje: '',
        reim_dept_id: '',
        reim_fees_purp: '',
        key: this.reimForm.reim_fees_list.length
      };
      this.reimForm.reim_fees_list.push(item);
    },
    initData() {
      this.loadingFlag = true;
      this.getReimInfo();
      this.getCptt();
      this.getStffUser();
    },
    //启用编辑
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    //撤销编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.initData();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
        })
        .catch(() => {});
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              console.log('error submit!');
              this.$message.warning('请把必填内容补充完整');
              return false;
            }
          });
        })
        .catch(() => {});
    },
    // 保存
    saveInfo() {
      let reimForm = JSON.parse(JSON.stringify(this.reimForm));
      //转换时间类型
      if (reimForm.reim_appdate) {
        reimForm.reim_appdate = parseInt(Number(new Date(reimForm.reim_appdate).getTime()) / 1000);
      }
      if (reimForm.reim_reidate) {
        reimForm.reim_reidate = parseInt(Number(new Date(reimForm.reim_reidate).getTime()) / 1000);
      }
      //删除时候给后端传值
      this.reimFeesListCopy = this.reimFeesListCopy.filter(item => reimForm.reim_fees_list.every(item1 => item.reim_fees_id !== item1.reim_fees_id));
      for (let i in this.reimFeesListCopy) {
        this.reimFeesListCopy[i].destroy_flag = 1;
      }
      reimForm.reim_fees_list = reimForm.reim_fees_list.concat(this.reimFeesListCopy);
      post(reimAPI.editReim, reimForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            this.isShow = true;
            this.$emit('isShow', this.isShow);
            this.initData();
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          console.error(res);
        });
    },
    // 刷新按钮
    buttonRefresh() {
      this.initData();
    },
    //方法区

    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 获取表单信息
    getReimInfo() {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      get(reimAPI.getReimById, { reim_id: props.form_id })
        .then(res => {
          if (res.data.code === 0) {
            this.reimForm = res.data.data.form;
            this.btn = res.data.data.btn;
            if (this.reimForm.autoInc) this.btn.edit = false;
            this.stffForm.stff_name = this.reimForm.stff_name;
            this.stffForm.dept_name = this.reimForm.dept_name;
            this.stffForm.dept_team_name = this.reimForm.dept_team_name;
            this.stffForm.user_id = this.reimForm.user_id;
            this.stffForm.dept_id = this.reimForm.dept_id;
            this.stffForm.stff_id = this.reimForm.stff_id;
            this.reimFeesListCopy = JSON.parse(JSON.stringify(this.reimForm.reim_fees_list));
            this.calcChange();
            if (this.reimForm.autoInc) {
              this.reimForm.reim_fees_list.forEach(item => {
                item.reim_dept_name = item.reim_dept_name_rich;
              });
            }
            //用友抓取后不可反生效不可编辑
            this.$emit('isYongYou', false);
            this.yongYouStatus = '未抓取';
            this.reimForm.reim_fees_list.forEach(item => {
              if (item.ngl_accvouch_Status === 1) {
                this.btn.edit = false;
                this.$emit('isYongYou', true);
                this.yongYouStatus = '已抓取';
              }
            });
            setTimeout(() => {
              this.loadingFlag = false;
              //加载子表申请部门下拉
              this.$refs.reimFeesRef.setPartDeptList();
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          console.info('🚀 ~ file:PpayEditMain method: line:443 -----', res);
        });
    },
    // 计算 保留位数 转换时间戳
    calcChange() {
      this.reimForm.reim_appdate = this.reimForm.reim_appdate ? Number(this.reimForm.reim_appdate) * 1000 : null;
      this.reimForm.reim_reidate = this.reimForm.reim_reidate ? Number(this.reimForm.reim_reidate) * 1000 : null;
    }
  }
};
</script>

<style scoped lang="scss">
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}

.vd_edhea {
  position: relative;
}

.vd_export {
  position: absolute;
  top: 0;
  left: 65px;
}

.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}

.vd_dash {
  width: 100%;
  height: 1px;
  border-top: 1px dashed #e9e9e9;
  margin: 10px 0;
}

.vd_word {
  word-wrap: break-word;
  word-break: normal;
}

.vd_export2 {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 150px;
}
</style>
